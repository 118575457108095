/* eslint-disable */
/* istanbul ignore file */

import useIsSsr from 'hooks/useIsSsr';
import React from 'react';
import { useUserJwtData } from '../AuthenticationProvider/AuthenticationProvider';
import {
  ConversionEvent,
  ConversionEventOptions,
  handleConversionEvent,
} from './trackingScriptEvents';
import { initializeFacebook, initializeGTM } from './trackingScriptInitialisers';

export interface Props {
  consent: boolean;
}

export default function TrackingScripts({ consent }: Props) {
  React.useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      '3rd_party_marking_optimization_consent': consent ? 'true' : 'false',
    });
    if (consent) {
      initializeFacebook();
      initializeGTM();
    } else {
      window.fbq = undefined;
      window.gtag = undefined;
    }
  }, [consent]);
  return null;
}

export function useConversionEventTrigger() {
  const userGuid = useUserJwtData()?.guid;
  return React.useCallback(
    (event: ConversionEvent, options?: ConversionEventOptions) => {
      handleConversionEvent(event, {
        userGuid,
        ...(options || {}),
      });
    },
    [userGuid],
  );
}
export function useOnConversionEvent(event: ConversionEvent, options?: ConversionEventOptions) {
  const trigger = useConversionEventTrigger();
  const fired = React.useRef(false);
  const isSsr = useIsSsr();
  React.useEffect(() => {
    if (!fired.current && !isSsr) {
      fired.current = true;
      trigger(event, options);
    }
  }, [event, options, isSsr]);
}
