// Made the list alphabetical
const ACCEPTED_LOCALES = [
  'ca',
  'cs',
  'da',
  'de',
  'el',
  'en',
  'es',
  'fi',
  'fr',
  'hi',
  'hu',
  'id',
  'in',
  'it',
  'ja',
  'ko',
  'ms',
  'nl',
  'no',
  'ph',
  'pl',
  'pt',
  'ro',
  'sg',
  'sv',
  'th',
  'tr',
  'vi',
  'zh',
  'ar-AE',
  'ar-SA',
  'cs-CZ',
  'da-DK',
  'de-AT',
  'de-CH',
  'de-DE',
  'el-GR',
  'en-AU',
  'en-CA',
  'en-GB',
  'en-HK',
  'en-ID',
  'en-IE',
  'en-IN',
  'en-MY',
  'en-NZ',
  'en-PH',
  'en-SG',
  'en-US',
  'en-ZA',
  'es-AR',
  'es-CO',
  'es-CL',
  'es-ES',
  'es-MX',
  'es-PE',
  'es-US',
  'fi-FI',
  'fr-BE',
  'fr-CA',
  'fr-CH',
  'hi-IN',
  'hu-HU',
  'fr-FR',
  'id-ID',
  'it-CH',
  'it-IT',
  'ja-JP',
  'ko-KR',
  'ms-MY',
  'nl-BE',
  'nl-NL',
  'no-NO',
  'pl-PL',
  'pt-BR',
  'pt-PT',
  'ro-RO',
  'sv-SE',
  'th-TH',
  'tr-TR',
  'vi-VN',
  'zh-HK',
  'zh-TW',
];
const DEFAULT_LOCALE = 'en';
const RTL_LOCALES = ['ar-AE', 'ar-SA'];
module.exports = {
  default: {
    ACCEPTED_LOCALES,
    DEFAULT_LOCALE,
    RTL_LOCALES,
  },
  ACCEPTED_LOCALES,
  DEFAULT_LOCALE,
  RTL_LOCALES,
};
