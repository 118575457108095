import { AUTH_SESSIONS_URL, AUTH_URL, QURATED_AUTH_SESSIONS_URL } from 'apiUrls';
import { UserSourceData } from 'utils/extractUserSourceDataFromQueryString';
import { SecondFactorRequiredError } from './SecondFactorRequiredError';
import { SESSION_ID } from './sessionId';
import {
  AuthenticationTokenState,
  LoginError,
  OnePToken,
  RequestContext,
  RequestPasswordResetError,
  ResetPasswordError,
  UserData,
} from './Types';

class TokenError extends Error {
  status?: number;

  constructor(message: string, name: string, status: number | undefined = undefined) {
    super(message);
    this.name = name;
    this.status = status;
  }
}

export class OnePTokenError extends TokenError {
  onepTokenError: string;

  constructor(message: string, status: number | undefined = undefined) {
    super(message, 'OnePTokenError', status);
    this.onepTokenError = message;
  }
}

export class ZendeskTokenError extends TokenError {
  zendeskTokenError: string;

  constructor(message: string, status: number | undefined = undefined) {
    super(message, 'ZendeskTokenError', status);
    this.zendeskTokenError = message;
  }
}

export function isTokenExpired(token: string) {
  try {
    const payload = JSON.parse(atob(token.split('.')[1]));
    return payload.exp * 1000 < new Date().getTime();
  } catch (e) {
    return false;
  }
}

export function getUserDataFromToken(accessToken: string | undefined): UserData | null {
  if (!accessToken) {
    return null;
  }
  try {
    const payload = JSON.parse(atob(accessToken.split('.')[1]));
    return {
      guid: payload.guid || payload.sub,
      roles: payload.roles || [],
      country: payload.country || '',
      currency: payload.currency || '',
    };
  } catch (e) {
    return null;
  }
}

async function loadJsonResponse<T = any>(response: Response): Promise<T> {
  if (response.status === 200 || response.status === 201) {
    return (await response.json()) as T;
  }
  if (
    response.status === 400 ||
    response.status === 401 ||
    response.status === 422 ||
    response.status === 405
  ) {
    const json = await response.json();
    throw new LoginError(json.error, 401, json);
  } else if (response.status === 401 || response.status === 422) {
    const json = await response.json();
    throw new LoginError(json.error, 401, json);
  } else if (response.status === 429 || response.status === 403) {
    throw new LoginError(
      'Too many login attempts. Double check the email address in the email field is correct, and try again in a few minutes.',
      response.status,
    );
  } else {
    const body = await response.text();
    // eslint-disable-next-line no-console
    console.warn(`Unexpected login error (${response.status}): ${body}`);
    throw new LoginError(
      'Some unexpected low level error occurred. Please try again',
      response.status,
      {},
    );
  }
}

// If a user tries to log in too many times they might get blocked and redirected to a very slow domain to slow down attackers.
// This will cause a 303 response without any CORS headers. Because there is no CORS headers the request gets blocked and raises an error instead of returning a response with a 303.
export async function throttleResilientFetch(
  input: RequestInfo,
  init?: RequestInit | undefined,
): Promise<Response> {
  try {
    return await fetch(input, init);
  } catch (err) {
    console.error('Fetch error: ', err);
    throw new LoginError(
      'Too many login attempts. You have been temporarily blocked for security reasons.',
      303,
    );
  }
}

export async function handleTokenResponse(response: Response): Promise<AuthenticationTokenState> {
  const data = await loadJsonResponse(response);
  return {
    refreshToken: data.refreshToken,
    accessToken: data.accessToken,
  };
}

const HEADERS: Record<string, string> =
  process.env.NEXT_PUBLIC_DEV_SECRET && !AUTH_URL?.includes('localhost')
    ? { 'Content-Type': 'application/json', 'Dev-Secret': process.env.NEXT_PUBLIC_DEV_SECRET }
    : { 'Content-Type': 'application/json' };

export type RegistrationData = {
  username: string;
  uid?: string;
  provider?: string;
  socialToken?: string;
  firstName: string;
  lastName: string;
  password: string;
  dateOfBirth: Date;
  terms: boolean;
  termsVersion: string;
  social?: {
    name: string;
    firstName: string;
    lastName: string;
  };
  nuDetectData?: string;
  nuDetectSession?: string;
  nuDetectPlacement?: string;
  nuDetectPlacementPage?: string;
};

type UsersCreateResponse = {
  otp?: string;
  error?: string;
  requiresEmailConfirmation?: boolean;
  panelistId?: string;
  session_id?: string;
};

export async function submitSignUp(
  requestContext: RequestContext,
  registrationData: RegistrationData,
  source?: UserSourceData,
  doiToken?: string,
): Promise<AuthenticationTokenState> {
  const dob = registrationData.dateOfBirth
    .toISOString()
    // We only care about the date part. We can cut the time
    .substring(0, 10);
  const bodyData = {
    user: {
      authentication_type: 'OTP',
      email: registrationData.username.includes('@') ? registrationData.username : undefined,
      phoneNumber: registrationData.username.includes('@') ? undefined : registrationData.username,
      password: registrationData.password,
      dateOfBirth: dob,
      date_of_birth: dob,
      firstName: registrationData.firstName,
      first_name: registrationData.firstName,
      lastName: registrationData.lastName,
      last_name: registrationData.lastName,
      termsOfServiceVersion: registrationData.termsVersion,
      terms: registrationData.terms,
      terms_of_service_version: registrationData.termsVersion,
      social_token: registrationData.socialToken ? registrationData.socialToken : undefined,
    },
    marketingEmailConsent: registrationData.terms,
    source: {
      inviteToken: source?.inviteToken,
      utmSource: source?.utmSource,
      utmMedium: source?.utmMedium,
      utmCampaign: source?.utmCampaign,
      utmContent: source?.utmContent,
      ...source,
    },
    nuDetect: {
      data: registrationData.nuDetectData,
      session: registrationData.nuDetectSession,
      placement: registrationData.nuDetectPlacement,
      placementPage: registrationData.nuDetectPlacementPage,
    },
    doiToken,
    social: registrationData.provider
      ? {
          provider: registrationData.provider,
          uid: registrationData.uid,
          info: {
            name: registrationData.social?.name,
            first_name: registrationData.social?.firstName,
            last_name: registrationData.social?.lastName,
          },
        }
      : undefined,
  };

  const response = await throttleResilientFetch(`${AUTH_URL}/users`, {
    method: 'POST',
    headers: {
      ...HEADERS,
      'Accept-Language': requestContext.locale ?? 'en-us',
    },
    credentials: 'include',
    body: JSON.stringify(bodyData),
  });

  let data;
  let loginError: LoginError | undefined;
  try {
    data = await loadJsonResponse<UsersCreateResponse>(response);
  } catch (e) {
    loginError = e as LoginError;
    data = loginError.payload;
  }
  if (data.session_id) {
    throw new SecondFactorRequiredError(data.session_id);
  } else if (data.error || loginError) {
    throw loginError || new LoginError(data.error, response.status);
  } else if (data.otp) {
    return await submitLoginOtp(requestContext, data.otp);
  } else {
    throw new LoginError('Unexpected login response', response.status);
  }
}

type UserSignInResponse = {
  otp?: string;
  session_id?: string;
  error?: string;
  details?: string;
};

export async function submitLogin(
  requestContext: RequestContext,
  username: string,
  password: string,
  sessionId?: string,
  twoFaCode?: string,
): Promise<AuthenticationTokenState> {
  let url, requestBody;
  if (sessionId && twoFaCode) {
    url = `${AUTH_URL}/users/2fa/sign-in`;
    requestBody = {
      user: {
        email: username.includes('@') ? username : undefined,
        phoneNumber: username.includes('@') ? undefined : username,
        password,
        session_id: sessionId,
        otp_2fa: twoFaCode,
      },
    };
  } else {
    url = `${AUTH_URL}/users/sign-in`;
    requestBody = {
      user: {
        authentication_type: 'OTP',
        email: username.includes('@') ? username : undefined,
        phoneNumber: username.includes('@') ? undefined : username,
        password,
      },
    };
  }
  const response = await throttleResilientFetch(url, {
    method: 'POST',
    headers: {
      ...HEADERS,
      'Accept-Language': requestContext.locale ?? 'en-us',
    },
    body: JSON.stringify(requestBody),
  });
  let data;
  let loginError: LoginError | undefined;
  try {
    data = await loadJsonResponse<UserSignInResponse>(response);
  } catch (e) {
    loginError = e as LoginError;
    data = loginError.payload || {};
  }
  if (data.session_id) {
    throw new SecondFactorRequiredError(data.session_id);
  } else if (data.error || data.details || loginError) {
    if (loginError) {
      throw loginError;
    } else {
      throw new LoginError(
        data.error || data.details || 'Unexpected login response',
        response.status,
      );
    }
  } else if (data.otp) {
    return await submitLoginOtp(requestContext, data.otp);
  } else {
    throw new LoginError('Unexpected login response', response.status);
  }
}

export async function submitLoginOtp(
  requestContext: RequestContext,
  otp: string,
): Promise<AuthenticationTokenState> {
  const sessionResponse = await throttleResilientFetch(
    `${AUTH_SESSIONS_URL}/user-sessions?otp=${encodeURIComponent(otp)}`,
    {
      method: 'POST',
      headers: {
        ...HEADERS,
        'Accept-Language': requestContext.locale ?? 'en-us',
        'x-tz-offset': new Date().getTimezoneOffset().toString(),
      },
      body: JSON.stringify({
        otp: otp,
      }),
      credentials: 'include',
    },
  );
  const tokens = await handleTokenResponse(sessionResponse);
  return tokens ? { ...tokens, firstPartyCookie: true } : tokens;
}

export async function logOut(
  requestContext: RequestContext,
  refreshToken: string | undefined,
  reason: string,
): Promise<void> {
  const response = await throttleResilientFetch(
    `${AUTH_SESSIONS_URL}/user-session?trigger=${reason}&id=${SESSION_ID}`,
    {
      method: 'DELETE',
      headers: {
        ...HEADERS,
        Authorization: `Bearer ${refreshToken}`,
        'Accept-Language': requestContext.locale ?? 'en-us',
      },
      credentials: 'include',
    },
  );
  if (response.status !== 200 && response.status !== 204) {
    try {
      const json = await response.json();
      // eslint-disable-next-line no-console
      console.warn(`Log out error (${response.status}):`, json);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(`Log out error (${response.status})`);
    }
  }
}

export async function fetchNewTokens(
  requestContext: RequestContext,
  refreshToken: string,
  reason: string,
  isFirstPartyCookie: boolean,
): Promise<AuthenticationTokenState> {
  let tokens: AuthenticationTokenState;
  let firstPartyRefreshToken: string | undefined = refreshToken;
  const tzOffset = new Date().getTimezoneOffset().toString();
  if (!isFirstPartyCookie) {
    // We need to stop using third party cookies because browsers are removing support (Ie refresh token cookie can not be set for auth-sessions.qurated.ai if it is set from app.lifepointspanel.com)
    // It the current tokens are not using a first party cookie we need to get the users full request token.
    // The auth-sessions.qurated.ai app has been set up to return the full token for that domain.
    // We can then use the fully returned token to swap it with a new token that is partially returned as a first party cookie.
    // This can be removed after 30 days once all refresh tokens have been migrated
    const response = await throttleResilientFetch(
      `${QURATED_AUTH_SESSIONS_URL}/user-session?id=${SESSION_ID}&reason=${reason}`,
      {
        headers: {
          ...HEADERS,
          Authorization: `Bearer ${refreshToken}`,
          'Accept-Language': requestContext.locale ?? 'en-us',
          'x-tz-offset': tzOffset,
        },
        credentials: 'include',
      },
    );
    tokens = await handleTokenResponse(response);
    if (!tokens?.refreshToken) {
      return tokens;
    }
    firstPartyRefreshToken = tokens?.refreshToken;
  }

  const response = await throttleResilientFetch(
    `${AUTH_SESSIONS_URL}/user-session?id=${SESSION_ID}&reason=${reason}`,
    {
      headers: {
        ...HEADERS,
        Authorization: `Bearer ${firstPartyRefreshToken}`,
        'Accept-Language': requestContext.locale ?? 'en-us',
        'x-tz-offset': tzOffset,
      },
      credentials: 'include',
    },
  );
  tokens = await handleTokenResponse(response);
  return tokens ? { ...tokens, firstPartyCookie: true } : tokens;
}

type ResetPasswordRequestResponse = {
  message: string;
  session_id?: string;
  error?: string;
};

export async function submitResetPasswordRequest(requestContext: RequestContext, username: string) {
  const response = await throttleResilientFetch(`${AUTH_URL}/users/password/request-reset`, {
    method: 'POST',
    headers: {
      ...HEADERS,
      'Accept-Language': requestContext.locale ?? 'en-us',
    },
    body: JSON.stringify({
      user: {
        email: username.includes('@') ? username : undefined,
        phoneNumber: username.includes('@') ? undefined : username,
        authentication_type: 'OTP',
      },
    }),
  });
  const json = (await response.json()) as ResetPasswordRequestResponse;
  if (response.status >= 200 && response.status <= 206) {
    if (json.session_id) {
      throw new SecondFactorRequiredError(json.session_id);
    }
    return {
      message: json.message,
    };
  }
  if (response.status > 400) {
    throw new RequestPasswordResetError(json.error || '', response.status);
  }
  throw new RequestPasswordResetError(
    'We are unable to request a reset of your password at the moment. Please try again later.',
    response.status,
  );
}

type PasswordResetResponse = {
  otp?: string;
  session_id?: string;
  error?: string;
  details?: string;
  message?: string;
};

export async function submitResetPassword(
  requestContext: RequestContext,
  password: string,
  passwordConfirmation: string,
  doiToken?: string,
  sessionId?: string,
  twoFaCode?: string,
) {
  let url, requestBody;
  if (sessionId && twoFaCode) {
    url = `${AUTH_URL}/users/2fa/password/reset`;
    requestBody = {
      user: {
        password,
        password_confirmation: passwordConfirmation,
        session_id: sessionId,
        otp_2fa: twoFaCode,
      },
    };
  } else {
    url = `${AUTH_URL}/users/password/reset`;
    requestBody = {
      user: {
        authentication_type: 'OTP',
        reset_password_token: doiToken,
        password,
        password_confirmation: passwordConfirmation,
      },
    };
  }
  const response = await throttleResilientFetch(url, {
    method: 'POST',
    headers: {
      ...HEADERS,
      'Accept-Language': requestContext.locale ?? 'en-us',
    },
    credentials: 'include',
    body: JSON.stringify(requestBody),
  });
  const json = (await response.json()) as PasswordResetResponse;
  if (json.error || json.details) {
    throw new ResetPasswordError(
      json.error || json.details || 'Unexpected reset password response',
    );
  } else if (json.otp) {
    return {
      otp: json.otp,
    };
  } else if (json.session_id) {
    throw new SecondFactorRequiredError(json.session_id);
  } else {
    throw new ResetPasswordError(json.message || 'Unexpected reset password response');
  }
}

export async function fetchOnePToken(accessToken: string, locale: string): Promise<OnePToken> {
  const response = await throttleResilientFetch(`${AUTH_URL}/users/onep-basic-token`, {
    headers: {
      ...HEADERS,
      locale: locale,
      'Accept-Language': locale,
      Authorization: `Bearer ${accessToken}`,
    },
    credentials: 'include',
  });
  const token = await response.text();
  if (response.status >= 200 && response.status <= 206) {
    return {
      jwt: token,
    };
  }
  // eslint-disable-next-line no-console
  console.warn(`Unexpected onep token error (${response.status}): ${token}`);
  throw new OnePTokenError('An error retrieving a OneP token occurred.', response.status);
}

export async function fetchZendeskToken(
  accessToken: string | null,
  locale: string,
): Promise<string> {
  if (accessToken == null) return '';

  const response = await throttleResilientFetch(`${AUTH_URL}/users/zendesk-token`, {
    headers: {
      ...HEADERS,
      locale: locale.toLowerCase(),
      'Accept-Language': locale,
      Authorization: `Bearer ${accessToken}`,
    },
    credentials: 'include',
  });
  const token = await response.text();
  if (response.status >= 200 && response.status <= 206) {
    return token;
  }

  // eslint-disable-next-line no-console
  console.warn(`Unexpected Zendesk token error (${response.status}): ${token}`);
  throw new ZendeskTokenError('An error retrieving a Zendesk token occurred.', response.status);
}

export async function confirm2fa(
  requestContext: RequestContext,
  sessionId: string,
  twoFaCode: string,
): Promise<AuthenticationTokenState> {
  const response = await throttleResilientFetch(`${AUTH_URL}/users/2fa/confirmation`, {
    method: 'POST',
    headers: {
      ...HEADERS,
      'Accept-Language': requestContext.locale ?? 'en-us',
      Accept: 'application/json',
    },
    body: JSON.stringify({
      user: {
        session_id: sessionId,
        otp_2fa: twoFaCode,
      },
    }),
  });
  const data = await loadJsonResponse<UserSignInResponse>(response);
  if (data.error || data.details) {
    throw new LoginError(
      data.error || data.details || 'Unexpected login response',
      response.status,
    );
  } else if (data.otp) {
    return await submitLoginOtp(requestContext, data.otp);
  } else if (data.session_id) {
    throw new SecondFactorRequiredError(data.session_id);
  } else {
    throw new LoginError('Unexpected login response', response.status);
  }
}
