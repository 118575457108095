import clsx from 'clsx';
import Txt from 'components/atoms/Txt/Txt';
import { LocaleInfo } from './getLocaleInfo';
import classes from './LanguageListItem.module.scss';
import { MdOutlineCheck } from 'react-icons/md';

export type Props = {
  localeInfo: LocaleInfo;
  checked: boolean;
  className?: string;
  languageClassName?: string;
  onLanguagePick?: (locale: string | undefined) => void;
};

export default function LanguageListItem({
  localeInfo,
  checked,
  className,
  languageClassName,
  onLanguagePick,
}: Props): JSX.Element {
  return (
    <Txt
      className={clsx(classes.root, className)}
      onClick={() => onLanguagePick && onLanguagePick(localeInfo?.locale)}
      skipTranslation
      dir={localeInfo.language.rtl ? 'rtl' : 'ltr'}
    >
      <div className={classes.check}>{checked ? <MdOutlineCheck /> : localeInfo.countryEmoji}</div>
      <div className={classes.label}>
        <Txt className={clsx(classes.language, languageClassName)} skipTranslation>
          {localeInfo.language?.native || localeInfo.locale}
        </Txt>
        <Txt className={classes.country} skipTranslation>
          {localeInfo.country?.native}
        </Txt>
      </div>
    </Txt>
  );
}
