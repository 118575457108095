// @ts-nocheck
/* eslint-disable */
/* istanbul ignore file */

const trackingEnabled =
  process.env.NODE_ENV === 'production' || process.env.NEXT_PUBLIC_ALWAYS_TRACK === 'true'; // NEXT_PUBLIC_ALWAYS_TRACK is for ease of development

export function initializeGTM() {
  if (trackingEnabled) {
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
      var n = d.querySelector('[nonce]');
      n && f.setAttribute('nonce', n.nonce || n.getAttribute('nonce'));
    })(window, document, 'script', 'dataLayer', 'GTM-M8TNB5T');
  }
}

export function initializeFacebook() {
  if (trackingEnabled) {
    !(function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = 'defer';
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
    window.fbq('init', 649085355587136);
  }
}
