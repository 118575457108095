import { createClient } from 'graphql-ws';
import {
  Environment,
  FetchFunction,
  GraphQLResponse,
  Network,
  Observable,
  RecordSource,
  RequestParameters,
  Store,
  SubscribeFunction,
  Variables,
} from 'relay-runtime';
import { RecordMap } from 'relay-runtime/lib/store/RelayStoreTypes';
import { GATEWAY_SUBSCRIPTION_URL, GATEWAY_URL } from '../apiUrls';

interface EnvironmentOptions {
  getAccessToken: (forceRefresh?: string) => Promise<string | null>;
  onError?: (error: Error) => void;
  records?: RecordMap;
  recordSource?: RecordSource;
  locale?: string;
}

export default function createRelayEnvironment({
  getAccessToken,
  onError,
  records,
  recordSource,
  locale,
}: EnvironmentOptions) {
  const source = recordSource || new RecordSource(records);
  const store = new Store(source);
  async function fetchResponse(
    request: RequestParameters,
    variables: Variables,
    accessToken: string | null,
  ) {
    const body = JSON.stringify({
      query: request.text, // GraphQL text from input
      variables,
    });

    const headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      'x-panel': process.env.NEXT_PUBLIC_PANEL || '',
      ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
      ...(locale ? { 'X-QMEE-LOCALE': locale, 'Accept-Language': locale } : {}),
    };
    return fetch(GATEWAY_URL, {
      credentials: 'include',
      method: 'POST',
      body,
      headers,
    });
  }
  const fetchQuery: FetchFunction = async (request: RequestParameters, variables: Variables) => {
    let accessToken = await getAccessToken();
    let response = await fetchResponse(request, variables, accessToken);

    if (response.status === 401 && accessToken) {
      accessToken = await getAccessToken('gateway-401');
      if (!accessToken) {
        throw new Error('Authentication error');
      }
      response = await fetchResponse(request, variables, accessToken);
      if (response.status === 401) {
        throw new Error('Authentication error');
      }
    }
    if (response.status !== 200) {
      const data = await response.text();
      throw new Error(`Server error (${response.status}): ${data}`);
    }
    const data = await response.json();
    if (data?.errors) {
      data.errors.forEach((error: any) => {
        if (onError) {
          onError(error);
        }
        // eslint-disable-next-line no-console
        console.warn(`Relay error when fetching ${error.path || request.text}: ${error.message}`);
      });
    }
    return data;
  };

  function createSubscription(): SubscribeFunction | undefined {
    if (typeof window !== 'undefined') {
      const wsClient = createClient({
        url: GATEWAY_SUBSCRIPTION_URL,
        connectionParams: async () => ({
          token: await getAccessToken(),
        }),
      });
      return function subscribe(
        operation: RequestParameters,
        variables: Variables,
      ): Observable<GraphQLResponse> {
        return Observable.create((sink) => {
          return wsClient.subscribe(
            {
              operationName: operation.name,
              query: operation.text || '',
              variables,
            },
            sink as any,
          );
        });
      };
    } else {
      return undefined;
    }
  }

  return new Environment({
    network: Network.create(fetchQuery, createSubscription()),
    store,
  });
}
