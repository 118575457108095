export class SecondFactorRequiredError extends Error {
  private readonly sessionId: string;

  constructor(sessionId: string) {
    super('');
    this.name = 'SecondFactorRequiredError';
    this.sessionId = sessionId;
  }

  getSessionId() {
    return this.sessionId;
  }
}
